
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Référence fournisseur"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Référence fournisseur"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.supplier_reference"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo"
              label-for="Photo"
            >
            
            <b-media vertical-align="top" v-if="file!==null">
                  <template #aside>
                    <b-img
                      :src="file"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editPhoto()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAdded($event)"
            />
        
            </b-form-group>
          </b-col>
           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

            <b-form-group
              label="Stock"
              label-for="Stock"
            >
            <validation-provider
              #default="{ errors }"
              name="Stock"
              rules="required|integer"
            >
              <b-form-input
                id="Stock"
                v-model="form.stock"
                :plaintext="readOnly"
                type="number"
                step="1"
                :state="errors.length > 0 ? false:null"
                placeholder="Stock"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                type="number"
                step="0.01"
                placeholder="Prix"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Catégorie"
              label-for="Type"
            >
            <v-select
                v-model="form.tint_categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
              
                multiple
                :options="tintCatChoices"
              />
             
            </b-form-group>
          </b-col>
            <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Options de verre"
              label-for="Type de verre"
            >
            <validation-provider
              #default="{ errors }"
              name="Options de verre"
              rules="required"
            >
              <v-select
                v-model="form.glass_options"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :state="errors.length > 0 ? false:null"
                multiple
                :options="glassOptionChoices"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Code"
              label-for="Code"
            >
            <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.code"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="#ffffff"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Teinte (hue)"
              label-for="Teinte"
            >
             <validation-provider
              #default="{ errors }"
              name="Teinte"
              
            >
              <b-form-input
              
                id="Hue"
                v-model="form.hue"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Teinte (hue)"
              label-for="Teinte"
            >
             <validation-provider
              #default="{ errors }"
              name="Teinte"
              
            >
              <b-form-input
              
                id="Hue"
                v-model="form.hue_app"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Saturation"
              label-for="Saturation"
            >
            <validation-provider
              #default="{ errors }"
              name="Code"
             
            >
              <b-form-input
              
                id="Saturation"
                v-model="form.saturation"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Opacity"
              label-for="Opacity"
            >
            <validation-provider
              #default="{ errors }"
              name="Code"
             
            >
              <b-form-input
              
                id="Opacity"
                v-model="form.opacity"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Luminosité"
              label-for="Luminosité"
            >
            <validation-provider
              #default="{ errors }"
              name="Code"
             
            >
              <b-form-input
              
                id="Luminosité"
                v-model="form.brightness"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Lense Color ID"
              label-for="Lense Color ID"
            >
             <validation-provider
              #default="{ errors }"
              name="Lense Color ID"
              
            >
              <b-form-input
              
                id="basicInput"
                v-model="form.type"
                :plaintext="readOnly"
                placeholder="Lense Color ID"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>

            <b-form-group
              label="Ordre d'affichage"
              label-for="Ordre d'affichage"
            >
           
            
              <b-form-input
                id="Ordre d'affichage"
                v-model="form.order_display"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Ordre d'affichage"
               
              />
             
            </b-form-group>
            
          </b-col>

        </b-row>
      </b-form>
      </validation-observer>

    </b-card>


     <b-button v-if="edition==false"
     @click="createTint"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editTint"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput,BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'TintForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BOverlay,
    BCardText, 
    BMedia, 
    BImg,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          glass_options:[],
          glassOptionChoices:[],
          edition:false,
          currentBase64:null,
          file: null,
          loading:true,
          tint_categories:[],
          tintCatChoices:[],
          form:{
            name:"",
            transparent_only:false,
            file:'',
            unity_id:null,
            price:0,
            supplier_reference:null,
            stock:0,
            photo:'',
            type:null,
            order_display:0,
            code:'',
            tint_categories:null,
            glass_options:null,
            hue:null,
            hue_app:null,
            saturation:null,
            opacity:null,
            brightness:null,
          },
      }
  },
  props: ['tint', 'readOnly'],
  methods:{
    editPhoto() {
      this.file=null
    },
    async getGlassOptions() {
      let glass_options = await APIRequest.list('glass-options')
      this.glass_options=glass_options[0];
      this.glassOptionChoices=Utils.formatSelect(this.glass_options,'name','id')
     
    },
    async getTintCategories() {
      let tint_categories = await APIRequest.list('tint-categories')
      this.tint_categories=tint_categories[0];
      this.tintCatChoices=Utils.formatSelect(this.tint_categories,'name','id')
      
    },
    async createTint()
    {
       this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        if(this.form.tint_categories!=null)
        {
          let category_id = this.form.tint_categories.value
          this.form.tint_categories={}
          this.form.tint_categories.id=category_id
        }
        try {

          var glass_options=this.form.glass_options
          this.form.glass_options=[]
          if(glass_options!=null)
          {
            for(var i=0;i<glass_options.length;i++)
            {
              this.form.glass_options[i]={}
              this.form.glass_options[i].id=glass_options[i].value
            }
          }

          var tint_categories=this.form.tint_categories
          this.form.tint_categories=[]
          if(tint_categories!=null)
          {
            for(var i=0;i<tint_categories.length;i++)
            {
              this.form.tint_categories[i]={}
              this.form.tint_categories[i].id=tint_categories[i].value
            }
          }

          let tint=await APIRequest.create('tints',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La teinte a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'editTint', params: { id: tint.id }})
          //this.$router.push({name:'tints'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
       })
      
    },
    async editTint()
    {
       this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        
        try {

          var glass_options=this.form.glass_options
          this.form.glass_options=[]
          if(glass_options!=null)
          {
            for(var i=0;i<glass_options.length;i++)
            {
              this.form.glass_options[i]={}
              this.form.glass_options[i].id=glass_options[i].value
            }
          }

          var tint_categories=this.form.tint_categories
          this.form.tint_categories=[]
          if(tint_categories!=null)
          {
            for(var i=0;i<tint_categories.length;i++)
            {
              this.form.tint_categories[i]={}
              this.form.tint_categories[i].id=tint_categories[i].value
            }
          }

          await APIRequest.update('tints',this.tint.id,this.form)
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La teinte a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loading=false
          //this.$router.push({name:'tints'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
       })
      
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
          
          let b64=reader.result.split(';')
          let type=b64[0]
          if(type.includes('image'))
          {
              let base64=b64[1]
              //base64.replace('base64,','')
              this.currentBase64=reader.result
              this.file=this.currentBase64
              this.form.photo=this.currentBase64
          }
          else
          {
              this.file=null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ceci n'est pas une image !",
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
          }
          
      };
    },
     
      
  },
  async mounted(){
    await this.getGlassOptions()
    await this.getTintCategories();

    if(this.tint!=null)
    {
      this.form=this.tint
      this.file=this.tint.photo
      this.edition=true

      if(this.tint.tint_categories!=null)
      {
        for(let j=0;j<this.tint.tint_categories.length;j++)
        {
          for(let i=0;i<this.tintCatChoices.length;i++)
          {
            if(this.tint.tint_categories[j].id == this.tintCatChoices[i].value)
            {
              this.form.tint_categories[j] = this.tintCatChoices[i]
            }
          }
        }
      }

      if(this.tint.glass_options!=null)
      {
        for(let j=0;j<this.tint.glass_options.length;j++)
        {
          for(let i=0;i<this.glassOptionChoices.length;i++)
          {
            if(this.tint.glass_options[j].id == this.glassOptionChoices[i].value)
            {
              this.form.glass_options[j] = this.glassOptionChoices[i]
            }
          }
        }
      }
      
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>